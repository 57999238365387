import { SVGAttributes } from 'react'

const ShareIcon = (props: SVGAttributes<SVGElement>) => (
    <svg {...props} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.9965 0.464008L17.4969 19.6242C17.4774 19.7739 17.3993 19.8812 17.2626 19.9463C17.1389 20.0179 17.0152 20.0179 16.8916 19.9463L10.5353 16.7839L8.24074 19.8292C8.16263 19.9398 8.05522 19.9951 7.91853 19.9951C7.85344 19.9951 7.80462 19.9886 7.77207 19.9756C7.58981 19.917 7.49868 19.7836 7.49868 19.5754V15.4174C7.49868 15.3067 7.52797 15.2221 7.58656 15.1636L16.6865 3.45077L4.86243 13.6604C4.77781 13.719 4.68342 13.7483 4.57927 13.7483C4.50767 13.7483 4.44583 13.732 4.39376 13.6995L0.234334 11.6205C0.0781104 11.5489 0 11.4318 0 11.2691C0 11.1064 0.0683466 10.9763 0.205042 10.8786L19.3716 0.0540594C19.5278 -0.0240264 19.6743 -0.0175192 19.811 0.0735809C19.9542 0.177695 20.016 0.307836 19.9965 0.464008Z"
        />
    </svg>
)
export default ShareIcon
