import { SVGAttributes } from 'react'

const EmbedIcon = (props: SVGAttributes<SVGElement>) => (
    <svg {...props} viewBox="0 0 30 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.1999 0C21.2859 0 21.3718 0.0351388 21.4578 0.105417L29.8969 8.72328C29.9656 8.79941 30 8.89019 30 8.99561C30 9.10103 29.9656 9.1918 29.8969 9.26794L21.4578 17.8858C21.389 17.9619 21.3016 18 21.1957 18C21.0897 18 21.0023 17.9619 20.9335 17.8858L18.364 15.2592C18.2952 15.1889 18.2609 15.0996 18.2609 14.9912C18.2609 14.8829 18.2952 14.7936 18.364 14.7233L23.9672 8.99561L18.364 3.26794C18.2952 3.19766 18.2609 3.10835 18.2609 3C18.2609 2.89165 18.2952 2.80234 18.364 2.73206L20.9335 0.105417C21.0195 0.0351388 21.1083 0 21.1999 0ZM8.80005 18C8.71411 18 8.62818 17.9649 8.54224 17.8946L0.103126 9.27672C0.034375 9.20059 0 9.10981 0 9.00439C0 8.89898 0.034375 8.8082 0.103126 8.73206L8.54224 0.114202C8.61099 0.0380672 8.69836 0 8.80435 0C8.91034 0 8.99771 0.0380672 9.06646 0.114202L11.636 2.74085C11.7048 2.81113 11.7391 2.90044 11.7391 3.00878C11.7391 3.11713 11.7048 3.20644 11.636 3.27672L6.01566 9.00439L11.636 14.7321C11.7048 14.8023 11.7391 14.8917 11.7391 15C11.7391 15.1083 11.7048 15.1977 11.636 15.2679L9.06646 17.8946C8.98052 17.9649 8.89172 18 8.80005 18Z"
        />
    </svg>
)
export default EmbedIcon
