'use client'
import { IGif } from '@giphy/js-types'
import { Gif } from '@giphy/react-components'
import EmbedPanel from './menu/desktop/embed-panel'
import DownloadPanel from './menu/desktop/download-panel'
import SharePanel from './menu/desktop/share-panel'
import { getGifHeight, getGifWidth } from '@giphy/js-util'
import { useContext } from 'react'
import UserContext from '@/context/user-context'
export const desktopGifWidth = 500
const gifMaxHeight = 540

type Props = { gif: IGif }

const GifWithMenu = ({ gif }: Props) => {
    const { user } = useContext(UserContext)
    let gifHeight = getGifHeight(gif, desktopGifWidth)
    let gifWidth = desktopGifWidth
    if (gifHeight > gifMaxHeight) {
        gifWidth = getGifWidth(gif, 540)
        gifHeight = 540
    }
    return (
        <div style={{ width: desktopGifWidth }}>
            <Gif
                backgroundColor="rgba(0,0,0,0)"
                noLink
                gif={gif}
                width={gifWidth}
                height={gifHeight}
                hideAttribution
                overlay={({ gif }: { gif: IGif }) => (
                    <>
                        <EmbedPanel gif={gif} />
                        {user?.is_staff && <DownloadPanel gif={gif} />}
                        <SharePanel gif={gif} />
                    </>
                )}
            />
        </div>
    )
}
export default GifWithMenu
