import { SVGAttributes } from 'react'

const DownloadIcon = (props: SVGAttributes<SVGElement>) => (
    <svg viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.5677 20H0.432318C0.311788 20 0.209673 19.9593 0.125971 19.8779C0.0422694 19.7965 0.000419076 19.6973 0.000419076 19.5801V17.0801C0.000419076 16.9694 0.0422694 16.8734 0.125971 16.792C0.209673 16.7106 0.311788 16.6699 0.432318 16.6699H17.5677C17.6882 16.6699 17.7903 16.7106 17.874 16.792C17.9577 16.8734 17.9996 16.9694 17.9996 17.0801V19.5801C17.9996 19.6973 17.9577 19.7965 17.874 19.8779C17.7903 19.9593 17.6882 20 17.5677 20ZM17.879 6.55273L9.31137 14.8828C9.19753 14.9609 9.09375 15 9 15C8.90625 15 8.80247 14.9609 8.68863 14.8828L0.120949 6.55273C0.00711451 6.44206 -0.026366 6.28581 0.020507 6.08398C0.0941647 5.91471 0.231434 5.83008 0.432318 5.83008H5.14304V0.419922C5.14304 0.302734 5.18489 0.203451 5.26859 0.12207C5.35229 0.0406896 5.45441 0 5.57494 0H12.4251C12.5456 0 12.6477 0.0406896 12.7314 0.12207C12.8151 0.203451 12.857 0.302734 12.857 0.419922V5.83008H17.5677C17.7686 5.83008 17.9058 5.91471 17.9795 6.08398C18.0264 6.28581 17.9929 6.44206 17.879 6.55273Z"
        />
    </svg>
)
export default DownloadIcon
